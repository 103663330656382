.footer-container {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.415) 0px 3px 6px;
  background: fixed;
  bottom: 0;
  /* position: absolute; */
}
.connect-icons {
  display: flex;
  justify-content: start;
  align-items: center;
}
.footer-head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}
.footer-head img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}
.footer-head p {
  margin: 0;
  padding: 0;
}
.footer-head h4{
  margin: 4px;
}
.footer-btn{
  margin-top: 20px;
}
.footer-head h2 {
  margin: 2px;
  color: var(--orange-color);
}
.footer-end-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: var(--light-grey);
  padding: 10px;
  margin-top: 20px;
}
.footer-end-container p {
  font-size: 12px;
  margin: 0;
}
.footer-list li {
  cursor: pointer;
}
.footer-end-info p{
margin-bottom: 10px;
}

@media (max-width: 992px) {
  .footer-end-container {
    flex-direction: column;
  }

  .footer-end-container img {
    height: 30px;
    width: 100px;
  }
}
@media (max-width: 768px){

}
