.blogpage-container {
  background-image: url(../../../assets/Images/cloudsImage.jpg);
  padding: 40px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
}
.blog-area-heading {
  text-align: center;
  font-size: 45px;
}
@media (max-width: 768px) {
  .blog-area-heading {
    font-size: 35px;
  }
  .blogpage-container {
    padding: 20px 0px;
    width: 100vw;
  }
}
