.blog-card-inner {
  display: flex;
  justify-content: space-around;
}

.blog-card-image {
  position: relative;
}
.blog-card-image::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 60%;
  transform: translate(-50%, -50%);
  opacity: 0;
}
.blog-card-image:hover::before {
  animation: circle 0.75s;
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

.css-1jgmn5n-MuiTypography-root {
  color: black !important;
  font-weight: 700 !important;
  margin: 0px !important;
}
