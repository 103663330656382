.form-container {
  position: relative;
  box-shadow: 6px 6px 18px 0px rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-top: -10%;
  z-index: 10;
}
.form-heading {
  display: flex;
  border-radius: 4px;
}
.form-heading-left {
  width: 40%;
  background-color: var(--blue-color);
  color: var(--white-color);
  float: left;
  text-align: start;
  padding: 10px 0 10px 30px;
}
.form-heading-left span {
  font-size: 16px;
  font-weight: 600;
}
.form-heading-right {
  width: 60%;
  background-color: var(--light-grey);
  color: rgb(126, 126, 126);
  float: right;
  text-align: end;
  padding: 10px 30px 10px 0;
}
.form-heading-right span {
  font-size: 14px;
}

.form-heading h4 {
  margin: 2px;
}

.form-container h2 {
  font-weight: 700;
  font-size: 45px;
  color: #09113c !important;
  line-height: 1.1em;
  text-align: center;
  margin: 30px 0 0 0;
}

.css-1633um2-MuiPaper-root {
  background-color: transparent !important;
}
.css-cnd76u-MuiPaper-root {
  box-shadow: none !important;
  margin: 5px 0 !important;
}

.custom-placeholder::placeholder {
  font-size: 8px;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 10px !important;
}
.form-checkbox {
  text-align: right;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 12px !important;
}
.css-i4bv87-MuiSvgIcon-root {
  width: 15px !important;
  height: 15px !important;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 4px !important;
}
.form-submit-button {
  display: flex;
  justify-content: right;
}
.form-radio-buttons {
  text-align: left !important;
}
.select-placeholder {
  margin: 0 !important;
  color: #bcbcbc  !important;
}

/* Responsive css */
@media (max-width: 992px) {
  .form-container {
    margin-top: -12%;
  }

  .form-container h2 {
    font-size: 35px;
    margin: 0px;
  }
}

@media (max-width: 768px) {
  .form-container {
    margin-top: -18%;
  }
  .form-heading-left {
    padding: 10px 0 10px 10px;
  }
  .form-heading-left span {
    font-size: 14px;
  }
}

@media (max-width: 556px) {
  .form-container h2 {
    font-size: 30px;
  }
  .form-heading-left {
    width: 50%;
  }
  .form-heading-right {
    width: 50%;
  }
  .form-heading-left span {
    font-size: 12px;
    font-weight: 500;
  }
}
