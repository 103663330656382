.subcategory-img {
  padding: 5%;
  width: 100%;
}
.subcategory-content {
  padding: 0 40px;
  width: 100%;
}
.subcategory-header-img {
  width: 100%;
  height: 280px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 30px;
}
.subcategory-header-img h1 {
  color: white;
  position: absolute;
  top: 15%;
  left: 10%;
  font-size: 35px;
}
/* .subcategory-header-img p{
  color: white !important;
  position: absolute;
  top: 20%;

} */

.category-main-title {
  font-size: 25px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .subcategory-header-img {
    height: 180px;
  }
  .subcategory-content {
    padding: 0 20px;
  }
  .subcategory-header-img h1 {
    font-size: 35px;
  }
}
@media (max-width: 600px) {
  .subcategory-header-img h1 {
    font-size: 30px;
  }
  .subcategory-content {
    padding: 0 10px;
  }
  .subcategory-img {
    padding: 0;
  }
}
