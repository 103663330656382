.skeleton-carousel {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
  height: 450px;
}

.skeleton-img {
  width: 100%;
  height: 450px;
  /* margin-top: -10%; */
}
