.select-jet-container {
  height: 600px;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.select-jet-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}


@media (max-width: 992px) {
  .select-jet-container {
    height: 400px;
  }
  .select-jet-container h1 {
    margin-top: 0;
    font-weight: 600;
  }
}

@media (max-width: 425px) {
  .select-jet-container h1 {
    font-size: 25px;
  }
}
