.blog-detail-img {
  width: 100%;
  height: 400px;
}
.blog-side-bar h5 {
  color: var(--orange-color);
}
.blog-side-bar h4 {
  color: var(--orange-color);
}
.blog-side-bar {
  padding: 0 40px;
}
.read-more-blogs{
  cursor: pointer;
}
.read-more-blogs:hover{
  text-decoration: underline;
}