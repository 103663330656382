.rent-jet-img {
  height: auto;
  width: 90%;
  padding: 5%;
}
.rent-jet-head {
  color: var(--blue-color);
}
@media (max-width: 992px) {
  .rent-jet-head {
    font-size: 25px;
    margin: 1px;
    line-height: 30px;
  }
  .rent-jet-para {
    font-size: 12px;
  }
}
