.contact-page-background {
  background-image: url(../../../assets/Images/contactPageBg.jpg);
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.contact-page-background h1 {
  position: absolute;
  top: 20%;
  left: 10%;
  color: white;
}
