.quote-page-background {
  background-image: url(../../../assets/Images/quotationPage.jpg);
  height: 300px;
  position: relative;
}
.quote-page-background h1 {
  position: absolute;
  left: 10%;
  top: 10%;
  color: white;
  font-size: 35px;
}

@media (max-width: 600px) {
  .quote-page-background h1{
    font-size: 30px;
  }
}