.quote-card-container {
  background-color: var(--blue-color);
  padding: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.quote-card-container h2 {
  font-size: 26px;
  font-weight: 500;
  color: var(--white-color);
}
.orange-line {
  background-color: var(--orange-color);
  height: 15px;
}

@media (max-width: 992px) {
  .quote-card-container {
    flex-direction: column;
    justify-content: center;
  }
}
