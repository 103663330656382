
.navbar-transition {
  transition: top 0.3s;
}

.view-all-blog {
  color: var(--blue-color);
  text-align: end;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.nav-info {
  text-align: center;
  color: black;
  padding: 2px;
  background-color: var(--orange-color);
  top: 0;
  left: 0;
  z-index: 1020;
}
.nav-info h6 {
  font-weight: 600;
  margin: 6px;
  font-size: 14px;
}
.nav-head h5 {
  margin: 0;
  color: var(--orange-color);
}
.navbar-main-logo {
  padding: 10px 20px;
  cursor: pointer;
}
.navbar-main-logo img {
  height: 60px;
  width: 100%;
}
.nav-head {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
}
.nav-head img {
  height: 30px;
  width: 40px;
  margin-right: 6px;
}
.nav-head p {
  margin: 0;
}
.nav-head h6 {
  margin: 0;
  font-size: xx-small;
  font-weight: 600;
}

/* white nav */

.nav-links {
  display: flex;
  justify-content: center;
  width: 100%;
}

.nav-links a {
  color: var(--blue-color);
  text-decoration: none;
  margin: 0 15px;
  font-size: 14px;
  text-transform: capitalize;
  border-left: 1px solid rgba(128, 128, 128, 0.608);
  border-radius: 0;
  padding: 5px 15px;
  font-weight: 500;
  height: fit-content;
}
.nav-links a:hover {
  color: rgb(128, 128, 128);
}
.css-1y942vo-MuiButtonBase-root-MuiButton-root:hover {
  background-color: none !important;
}
/* Styles for the third navbar */
.navbar-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1020;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.dropdown-nav {
  position: relative;
  z-index: 1500;
}
.dropdown-nav-list {
  display: none;
  position: absolute;
  background-color: white;
  z-index: 2000;
}
.dropdown-nav-list ul li {
  padding: 4px 40px 4px 10px;
  cursor: pointer;
  width: max-content;
  color: black;
}

.dropdown-nav:hover .dropdown-nav-list {
  display: block;
  border-top: 2px solid var(--orange-color);
}

/* Adjust margin to prevent content from jumping */
.navbar-margin {
  margin-top: 60px; /* Adjust according to the height of the navbar */
}

/* Add styles for smooth transition */
.navbar-transition {
  transition: top 0.3s;
}

.booking-logo {
  height: 40px;
  width: 40px;
}

.drawer-subcategories{
  padding: 10px;
}
@media (max-width: 992px) {
  .navbar-main-logo img {
    height: auto;
    width: 140px;
  }
  .nav-head {
    display: none;
  }
  .nav-links a {
    margin: 0px 10px;
    font-size: 12px;
    padding: 0 5px;
    height: auto;
  }
}

@media (max-width: 768px) {
  .nav-info h6 {
    font-size: 10px;
  }
  .nav-links a {
    margin: 0px 5px;

    padding: 0 2px;
  }
}

@media (max-width: 600px) {
  .booking-logo {
    height: 35px;
    width: 35px;
  }
}
