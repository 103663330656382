.logo-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-direction: row;
  gap: 20px;
  padding-top: 30px;
}

.contact-logo h4 {
  font-weight: 800;
  margin: 4px 0;
  font-size: 18px;
  color: var(--blue-color);
}
.contact-logo p {
  color: var(--orange-color);
  margin: 2px;
  font-weight: 800;
}
.contact-logo {
  text-align: center;
}
.contact-logo img {
  height: 60px;
  width: 60px;
  margin-bottom: 10px;
  background-color: var(--blue-color);
  padding: 4px;
  border-radius: 50%;
  object-fit: none;
}
.contact-logo-heading {
  font-size: 26px;
  text-align: center;
  color: var(--blue-color);
  margin-bottom: 2rem;
}

/* Responsive css */

@media (max-width: 900px) {
  .contact-logo-heading {
    font-size: 24px;
  }
  .contact-logo img {
    height: 50px;
    width: 50px;
  }

  .contact-logo h4 {
    font-size: 16px;
  }
}

/* Responsive css */
@media (max-width: 425px) {
  .contact-logo p {
    font-size: 12px;
  }
  .contact-logo h4 {
    font-size: 14px;
  }
  .logo-container {
    padding-top: 20px;
  }
}
