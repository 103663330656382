body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
:root {
  --orange-color: #faa01b;
  --blue-color: #060e3e;
  --light-grey: #ebebeb;
  --white-color: #fff;
  --text-color: #666;
}
a {
  text-decoration: none;
}
p {
  color: var(--text-color);
  font-size: 14px;
  line-height: 1.3rem;
}

ul li {
  color: var(--text-color);
  list-style: none;
  font-size: 14px;
  margin-top: 10px;
}
ul {
  padding: 0 !important;
}
.section-padding {
  margin-top: 60px;
  margin-bottom: 60px;
}
.color-text {
  color: var(--orange-color);
  cursor: pointer;
}
