.rent-jet-heading {
  text-align: center;
  font-size: 40px;
  color: var(--blue-color);
  font-weight: 700;
}
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: var(--blue-color) !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 14px;
} */

@media (max-width: 768px) {
  .rent-jet-heading {
    font-size: 35px;
  }
  .rent-jet-tab-head {
    font-size: 26px;
  }
}
@media (max-width: 425px) {
  .rent-jet-tab-head {
    font-size: 22px;
    font-weight: 700;
  }
}
